import axios from "axios";

const useServerRouters = async () => {
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    try {
        const {data} = await axios.get('https://svod.polyanaski.ru/api/market/salegroups', {
            headers: {
                'mrktsid': getCookie('server_sid') || ""
            },
            timeout: 4000
        });
        return data;
    } catch (e) {
        throw e;
    }
}

export default useServerRouters;