<script>
import {RouterLink, RouterView} from 'vue-router';
import MainMenu from "./components/MainMenu.vue";
import AuthPopup from "./components/popups/AuthPopup.vue";
import LogoutPopup from './components/popups/LogoutPopup.vue';
import SetEmailPopup from "@/components/popups/SetEmailPopup.vue";
import SbpQrCodePopup from "@/components/popups/SbpQrCodePopup.vue";
import {deleteUrlParam, isMobileDevice} from "@/hooks/fnHelper";
import GidQrClientAlertPopup from "@/components/popups/GidQrClientAlertPopup.vue";
import GidClientQrCodePopup from "@/components/popups/GidClientQrCodePopup.vue";

export default {
    components: {GidClientQrCodePopup, GidQrClientAlertPopup, SetEmailPopup, RouterLink, RouterView, AuthPopup, MainMenu, LogoutPopup, SbpQrCodePopup},
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showAuthPopup: false,
            showLogoutPopup: false,
            showSetEmailPopup: false,
            isAuth: false,
            user: null,
            authPayload: null,
            setEmailCallback: null,
            initialized: false,
            testMode: false,
            isGidClient: false,
            serviceMessage: false,
            message: 'Hello!',
            isBigMenu: false,
            sbpQrCodeParams: null,
            gidClientQrData: null,
            showGidClientAlertPopup: false,
            showGidClientQrPopup: false,
            gidClientModeEnable: true,
            linkHasHGid: false,
            isMobileDevice: isMobileDevice(),
            serverMenuItems: [],
        }
    },

    computed: {
        host() {
            return location.hostname === 'localhost' ? 'localhost' : 'polyanaski.ru';
        }
    },
    async created() {
        this.setUtmToCookie();
        if (this.$cookies.isKey("testMode")) {
            this.testMode = true;
        } else if (this.isTestMode()) {
            this.$cookies.set("testMode", true);
            this.$router.go();
        }

        const urlParams = new URLSearchParams(window.location.search);
        // console.log('q', urlParams.get('xuid'), this.$cookies.isKey("gazprom_sid"), this.$cookies.get("gazprom_sid"), location.search);

        console.log('route', this.$route.name);

        if (urlParams.get('xuid') && this.$cookies.isKey("gazprom_sid") && this.$cookies.get("gazprom_sid") === urlParams.get('xuid')) {
            // console.log('xuid', urlParams.get('xuid'));
            this.$cookies.set("server_sid", urlParams.get('xuid'), null, null, this.host);
            deleteUrlParam(this.$router, 'xuid');
        }

        if (urlParams.get('hgid')) {
            // console.log('this.$route.name', this.$route.name, this.$router);
            this.$cookies.set("is_gid_client", 1, "10min", null, this.host);
            if (~['/profile', '/profile/'].indexOf(location.pathname)) {
                this.$router.push({name: 'profile.gazprom'});
            } else {
                deleteUrlParam(this.$router, 'hgid');
            }
        }

        if (!this.$cookies.isKey("server_sid")) {
            await this.axios.get('https://api.polyanaski.ru/API/')
                .then(({data}) => {
                    if (data?.session?.sid) {
                        this.$cookies.set('server_sid', data.session.sid, null, null, this.host);
                    }
                });
        }

        if (this.$cookies.isKey("server_sid")) {
            if (!this.$cookies.isKey("mrktsid", "ski-pass.polyanaski.ru")) {
                this.$cookies.set("mrktsid", this.$cookies.get("server_sid"), null, null, "ski-pass.polyanaski.ru");
            }
            // console.log('this.$cookies.get("server_sid", "ski-pass.polyanaski.ru")', this.$cookies.get("server_sid", "ski-pass.polyanaski.ru"));

            await this.axios.get('https://get.polyanaski.ru/getall.dll/userdata?sid=' + this.$cookies.get("server_sid"))
                .then(({data}) => {
                    if (data.id) {
                        this.user = data;
                        this.isAuth = true;
                        // ym(51222278, 'setUserID', this.user.id);
                    }
                    this.initialized = true;
                }).catch(() => {
                    this.initialized = true;
                });
        } else {
            this.initialized = true;
        }

        await this.getServiceMessage();
    },

    mounted() {
        this.emitter.on("showSbpQrCodePopup", (params) => {
            console.log('showSbpQrCodePopup', params);
            this.sbpQrCodeParams = params;
        });

        this.emitter.on("showAuthPopup", payload => {
            console.log('showAuthPopup payload', payload)
            this.showAuthPopup = true;
            this.authPayload = payload;
        }, error => {
            console.log('error auth', error);
        });

        this.emitter.on("showLogoutPopup", () => {
            this.showLogoutPopup = true;
        });

        this.emitter.on("showSetEmailPopup", callback => {
            this.showSetEmailPopup = true;
            this.setEmailCallback = callback;
        });

        setTimeout(() => {
            // console.log('this.$refs.leftSide.clientHeight', this.$refs.leftSide.clientHeight,window.innerHeight - 66 );
            if (this.$refs.leftSide && (this.$refs.leftSide.clientHeight > window.innerHeight - 66)) {
                this.isBigMenu = true
            }
        }, 500);

        this.checkGidCookie();
    },

    methods: {
        onGidClientQrCodePopupClose() {
            this.showGidClientQrPopup = false;
            if (this.gidClientQrData === false) {
                this.gidClientQrData = null;
            }
        },
        showGidClientAlertPopupFn() {
            if (!this.$root.isAuth) {
                this.emitter.emit('showAuthPopup', {
                    onAuth: () => {
                        this.showGidClientAlertPopupFn();
                    }
                });
                return;
            }

            let gidClientQrData = null;

            if (this.gidClientQrData) {
                gidClientQrData = JSON.parse(JSON.stringify(this.gidClientQrData));
            } else if (this.$cookies.isKey("gidClientQrData")) {
                gidClientQrData = this.$cookies.get("gidClientQrData")
            }


            if (!gidClientQrData || gidClientQrData.endDate < Date.now()) {
                this.showGidClientAlertPopup = true;
                return;
            } else {
                this.showGidClientQrPopupFn();
            }
        },
        showGidClientQrPopupFn() {
            if (!this.$root.isAuth) {
                this.emitter.emit('showAuthPopup', {
                    onAuth: () => {
                        this.showGidClientQrPopupFn();
                    }
                });
                return;
            }

            this.axios.get('https://api.polyanaski.ru/' + (this.$root.testMode ? 'APId' : 'API') + '/gid/getgiddyndisct?paramline=' + btoa(JSON.stringify({
                "mrktsid": this.$cookies.get('server_sid')
            })))
                .then(({data}) => {
                    if (data?.getgiddyndisct?.promoqr) {
                        this.gidClientQrData = {
                            link: data.getgiddyndisct.promoqr,
                            endDate: Date.now() + (data.getgiddyndisct.alive * 1000),
                            alive: data.getgiddyndisct.alive,
                            promoCode: data.getgiddyndisct.promocode,
                        }

                        this.$cookies.set('gidClientQrData', JSON.stringify(this.gidClientQrData), new Date(this.gidClientQrData.endDate), null, this.host)
                    }
                }).catch(() => {
                this.$toast.error('Ошибка сервера! Обновите страницу и попробуйте еще раз.');
                this.gidClientQrData = false;
            });

            this.showGidClientQrPopup = true;
        },
        checkGidCookie() {
            if (this.isMobileDevice && this.$cookies.isKey("is_gid_client")) {
                this.isGidClient = true;
                /*if(this.$cookies.isKey("gidClientQrData")){
                    this.gidClientQrData = this.$cookies.get("gidClientQrData");
                }*/
                setInterval(() => {
                    this.$cookies.set("is_gid_client", 1, "10min", null, this.host);
                }, 60000); //  * 60 * 20
            }
        },
        getServiceMessage() {
            return Promise.all([
                this.axios.get('https://get.polyanaski.ru/getall.dll/objects?id=4213').then(({data}) => {
                    if (data.length && data[0].name && data[0].status === 1) {
                        this.serviceMessage = data[0];
                    }
                }),
                // this.test()
            ]);
        },
        async test() {
            if (false) {
                this.$cookies.remove("gidClientQrData");
                this.$cookies.remove("is_gid_client");
            } else {
                this.gidClientModeEnable = true;
            }
        },
        auth() {
            this.emitter.emit('showAuthPopup', {
                onAuth: () => {
                    this.$router.go();
                }
            })
        },
        isTestMode() {
            let uri = window.location.href.split('?');
            if (uri.length === 2) {
                let vars = uri[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length === 2)
                        getVars[tmp[0]] = tmp[1];
                });
                if (getVars.setTestMode && getVars.setTestMode.toUpperCase() === 'Y') {
                    return true;
                }
            }
            return false;
        },
        setUtmToCookie() {
            let uri = window.location.href.split('?');
            if (uri.length === 2) {
                let vars = uri[1].split('&');
                let getVars = {};
                let tmp = '';
                vars.forEach(function (v) {
                    tmp = v.split('=');
                    if (tmp.length === 2)
                        getVars[tmp[0].toLowerCase()] = tmp[1];
                });
                if (getVars.utm_source) {
                    this.$cookies.set("utm_source", getVars.utm_source, "1d", null, this.host);
                }
                if (getVars.utm_medium) {
                    this.$cookies.set("utm_medium", getVars.utm_medium, "1d", null, this.host);
                }
                if (getVars.utm_campaign) {
                    this.$cookies.set("utm_campaign", getVars.utm_campaign, "1d", null, this.host);
                }
                if (getVars.utm_content) {
                    this.$cookies.set("utm_content", getVars.utm_content, "1d", null, this.host);
                }
            }
            return false;
        },
        disableTestMode() {
            this.$cookies.remove('testMode');
            this.testMode = false;
            let query = Object.assign({}, this.$route.query);
            delete query.setTestMode;
            this.$router.push({query: query});
            setTimeout(() => {
                location.href = this.$route.fullPath;
            });
        },
    },

    unmounted() {
        this.emitter.off("showAuthPopup");
    },

    destroy() {
        this.emitter.off("showAuthPopup");
    }
}

</script>

<template>
    <template v-if="initialized">
        <template v-if="!$route.meta.singlePage">
            <div id="set-email-box"
                 v-if="isAuth && $route.name !== 'home'">
                <span class="user-name-box">{{ user.fname ? user.fname + ', рады' : 'Уважаемый Гость, рады' }}</span>
                <span class="user-name-box-mobile">{{ user.fname ? user.fname + ', рады' : 'Рады' }}</span>
                видеть Вас
                <template v-if="user.fname"> снова</template>
                !
                <!--                Вы можете
                                <router-link :to="{name: 'profile'}"><b>{{ user.fname ? 'изменить' : 'указать' }}</b></router-link>
                                свои данные.-->
            </div>
            <div id="set-email-box"
                 v-else-if="!isAuth">
                <a href
                   @click.prevent="auth">Авторизуйтесь</a>, чтобы покупать со скидкой
            </div>
        </template>
        <div id="wrapper"
             :class="{'single-page': $route.meta.singlePage}">
            <template v-if="$route.meta.singlePage">
                <div class="center-side">
                    <header>
                        <a href="https://polyanaski.ru/"><img src="@/assets/images/logo.svg"
                                                              class="header-logo"
                                                              alt="GTC Gazprom"></a>
                    </header>
                    <RouterView v-slot="{ Component, route }">
                        <!-- Use any custom transition and fallback to `fade` -->
                        <Transition :name="'fade'">
                            <component :is="Component"/>
                        </Transition>
                    </RouterView>
                </div>
            </template>
            <template v-else-if="$route.name !== 'home'">
                <div class="left-side"
                     ref="leftSide"
                     :style="isBigMenu ? 'position:absolute' : ''">
                    <header>
                        <a href="https://polyanaski.ru/"><img src="@/assets/images/logo.svg"
                                                              class="header-logo"
                                                              alt="GTC Gazprom"></a>
                    </header>

                    <MainMenu/>
                </div>

                <div class="right-side">
                    <RouterView v-slot="{ Component, route }">
                        <!-- Use any custom transition and fallback to `fade` -->
                        <Transition :name="'fade'">
                            <component v-if="Component"
                                       :is="Component"/>

                            <div class="content-box"
                                 v-else>
                                <div class="wait-box">
                                    <div class="wait-icon mb0">
                                        <img src="@/assets/images/loader.svg"
                                             alt="Wait...">
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </RouterView>
                </div>
            </template>
            <template v-else>
                <RouterView v-slot="{ Component, route }">
                    <!-- Use any custom transition and fallback to `fade` -->
                    <Transition :name="'fade'">
                        <component v-if="Component"
                                   :is="Component"/>

                        <div class="content-box"
                             v-else>
                            <div class="wait-box">
                                <div class="wait-icon mb0">
                                    <img src="@/assets/images/loader.svg"
                                         alt="Wait...">
                                </div>
                            </div>
                        </div>
                    </Transition>
                </RouterView>
            </template>

            <footer>
                <div class="footer-box">
                    <div class="footer-column">
                        <div class="top">
                            <a href="https://polyanaski.ru/"><img src="@/assets/images/logo.svg"
                                                                  class="footer-logo"
                                                                  alt="GTC Gazprom"></a>
                        </div>
                        <div class="middle">
                            <a href="https://polyanaski.ru/"
                               class="footer-menu-link">Перейти на сайт курорта «Газпром
                                Поляна»</a>
                        </div>
                        <div class="bottom">
                            <div class="address">
                                <svg class="icon icon-map-pin">
                                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-map-pin"></use>
                                </svg>
                                Россия, г.Сочи, с.Эстосадок, ул.Ачипсинская, 10
                            </div>
                            <div class="phone">
                                <svg class="icon icon-phone">
                                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-phone-wave"></use>
                                </svg>
                                <a href="tel:88005505333">8-800-5-505-333</a>
                            </div>
                        </div>
                    </div>
                    <div class="footer-column">
                        <div class="top"></div>
                        <div class="middle">
                            <!--                            <a href="https://polyanaski.ru/upload/medialibrary/rules/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%9E%D0%9E%D0%9E%20%D0%A1%D0%B2%D0%BE%D0%B4%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%88%D0%BD%D0%BB.pdf"
                                                           class="footer-menu-link">Политика обработки персональных данных</a>-->
                        </div>
                        <div class="bottom">
                            <div class="social-links">
                                <!--                                <a href="" class="social-link">
                                                                    <svg class="icon icon-fb">
                                                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-fb"></use>
                                                                    </svg>
                                                                </a>-->
                                <a href="https://vk.com/polyanaski"
                                   class="social-link"
                                   target="_blank">
                                    <svg class="icon icon-vk">
                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-vk"></use>
                                    </svg>
                                </a>
                                <!-- <a href="" class="social-link">
                                     <svg class="icon icon-ok">
                                         <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-ok"></use>
                                     </svg>
                                 </a> -->
                                <!--                                <a href="" class="social-link">
                                                                    <svg class="icon icon-instagram">
                                                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-instagram"></use>
                                                                    </svg>
                                                                </a>-->
                                <!--                                <a href="" class="social-link">
                                                                    <svg class="icon icon-twitter">
                                                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-twitter"></use>
                                                                    </svg>
                                                                </a>-->
                                <a href="https://www.youtube.com/channel/UCAHVVWmAKnEp7LdEt2eAlpQ"
                                   class="social-link"
                                   target="_blank">
                                    <svg class="icon icon-youtube">
                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-youtube"></use>
                                    </svg>
                                </a>
                                <a href="https://t.me/gazprom_resort"
                                   class="social-link"
                                   target="_blank">
                                    <svg class="icon icon-telegram">
                                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-telegram"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--                    <div class="footer-column">
                                            <div class="top"></div>
                                            <div class="middle">
                                                <a href="https://polyanaski.ru/sp20rules/" class="footer-menu-link">Как это работает?</a>
                                            </div>
                                            <div class="bottom"></div>
                                        </div>-->
                    <div class="footer-column">
                        <div class="top"></div>
                        <div class="middle">
                            <!--                            <a href="https://polyanaski.ru/sp20feedbacks/" class="footer-menu-link">Отзывы и предложения</a>-->
                            <a href="https://polyanaski.ru/upload/medialibrary/rules/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%9E%D0%9E%D0%9E%20%D0%A1%D0%B2%D0%BE%D0%B4%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%88%D0%BD%D0%BB.pdf"
                               class="footer-menu-link">Политика обработки персональных данных</a>
                        </div>
                        <div class="bottom">
                            <div class="copyright">
                                ГТЦ “Газпром Поляна” 2024 ©
                                Все права защищены
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <SbpQrCodePopup
            v-if="sbpQrCodeParams"
            :link="sbpQrCodeParams.link"
            :orderUID="sbpQrCodeParams.orderUID"
            :amount="sbpQrCodeParams.amount"
            @close="sbpQrCodeParams = null"
            :successFn="sbpQrCodeParams.successFn"
            :failFn="sbpQrCodeParams.failFn"/>
        <AuthPopup v-show="showAuthPopup"
                   :show="showAuthPopup"
                   @close="showAuthPopup = false"
                   :payload="authPayload"></AuthPopup>
        <SetEmailPopup v-show="showSetEmailPopup"
                       @close="showSetEmailPopup = false"
                       :callback="setEmailCallback"></SetEmailPopup>
        <LogoutPopup v-if="showLogoutPopup"
                     @close="showLogoutPopup = false"></LogoutPopup>
    </template>
    <template v-else>
        <div class="content-box min-h-100vh content-box-i wait-box">
            <div class="wait-icon">
                <img src="@/assets/images/loader.svg"
                     alt="Wait...">
            </div>
            <div class="wait-content">
                Загрузка данных...
            </div>
        </div>
    </template>
    <template v-if="gidClientModeEnable && isGidClient && isMobileDevice">
        <div class="is-gid-client-box">
            <div class="is-gid-client-on">Режим «ГИД»</div>
        </div>
        <div id="discount-code-box"
             @click="showGidClientAlertPopupFn">
            <svg class="icon icon-qr">
                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-qr"></use>
            </svg>
        </div>
        <GidQrClientAlertPopup
            v-if="showGidClientAlertPopup"
            @close="showGidClientAlertPopup = false"
            @continue="showGidClientQrPopupFn"
        />
        <GidClientQrCodePopup
            v-if="showGidClientQrPopup"
            :qrData="gidClientQrData"
            @close="onGidClientQrCodePopupClose"
        />
    </template>
    <template v-if="testMode">
        <div class="test-mode-box">
            <div class="test-mode-on">Тестовый режим!</div>
            <div class="test-mode-off"
                 @click="disableTestMode">Выключить
            </div>
        </div>
    </template>
    <div class="service-message-box"
         v-if="serviceMessage && serviceMessage.status === 1">
        <div class="service-message">{{ serviceMessage.description }}</div>
    </div>
</template>

<style lang="scss">
@import '@/assets/scss/style.scss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s linear;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
